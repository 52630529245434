import { Helmet } from 'react-helmet'

import classnames from 'classnames'

import MainLayout from '../Main'
import './application.scss'

export default ({
  children,
  className,
  footer,
  Header,
  scrollingRef,
  ...props
}) => {
  const classNamesLayout = classnames('application-layout', {
    [className]: className
  })

  const classNamesContent = classnames('application-content', {
    [className]: className
  })

  return (
    <div className="layout-wrapper">
      <div className="application-layout__content">
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <div {...props} className={classNamesLayout} ref={scrollingRef}>
          <MainLayout Header={Header} hasFooter={footer}>
            <main className={classNamesContent}>{children}</main>
          </MainLayout>
        </div>
      </div>
    </div>
  )
}
