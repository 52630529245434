import { useCallback, useEffect } from 'react'

import { useLocation } from '@reach/router'
import { goTo, storage } from 'utils'

export const useHandleQueryStrings = () => {
  const location = useLocation()

  const getQueries = (location) => {
    const uuid = new URLSearchParams(location?.search).get('u')

    return {
      uuid
    }
  }

  const saveToSessionStorage = (key, data) => {
    storage.session.setItem(key, JSON.stringify(data))
  }

  const handleQueryStrings = useCallback(() => {
    const fakeDelay = 1000
    const { uuid } = getQueries(location)

    saveToSessionStorage('token', { leadId: uuid })

    setTimeout(() => {
      goTo('validate-tokenization-code')
    }, fakeDelay)
  }, [location])

  useEffect(
    () => {
      handleQueryStrings()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
